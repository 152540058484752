import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import { mediaQueries, color } from '../variables';

export const SectionHeading = styled.h2`
  color: ${color.black};
  margin: 0 0 1rem;
  font-size: 40px;
  font-weight: 500;
  padding-bottom: '1rem';
  ${mediaQueries.lg} {
    font-size: 38px;
  }
  ${mediaQueries.md} {
    font-size: 28px;
  }
  ${mediaQueries.sm} {
    font-size: 22px;
    color: #1c4b81;
  }
`;

export const SectionIcon = styled.img(({ large }) => ({
  bottom: large ? 0 : '7rem',
  display: 'block',
  position: 'relative',
  width: large ? '41rem' : '35rem',
  height: large ? '31rem' : '29rem',
  backgroundRepeat: 'no-repeat',
  [mediaQueries.xl]: {
    width: '20rem',
    height: '23rem',
  },
  [mediaQueries.lg]: {
    bottom: '5rem',
  },
  [mediaQueries.md]: {
    display: 'flex',
    margin: '0 auto',
    bottom: 0,
    height: '16rem',
  },
  [mediaQueries.sm]: {
    width: '17rem',
  },
}));

export const SectionWrapper = styled(Col)(({ nobottompadding }) => ({
  padding: nobottompadding ? '7rem 0 0 ' : '7rem 0',
  width: '100%',
  backgroundColor: color.white,
  backgroundSize: 'cover',
  [mediaQueries.sm]: {
    boxShadow: '0 8px 18px 0 #dee7f2',
    borderRadius: '1rem',
    width: '90vw',
    padding: 0,
    margin: '0 auto',
  },
}));

export const ContentRow = styled(Row)`
  width: 100%;
  display: flex;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
  ${mediaQueries.sm} {
    margin-bottom: 2rem;
  }
`;

export const ContentWrapper = styled.div`
  text-align: left;
  margin: 0 10rem;
  ${mediaQueries.md} {
    text-align: center;
    margin: 0 5rem;
  }
  ${mediaQueries.sm} {
    margin: 0;
    padding: 2rem;
  }
`;
