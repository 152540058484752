import React from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

import { mediaQueries, color } from '../variables';
import dedicatedSupportIcon from '../../images/illu-dedicated-support.svg';
import * as S from '../shared/components/styles';
import { SectionIcon } from './styles';
import { SectionWrapper, ContentRow } from '../businesses/styles';

const ContentWrapper = styled.div`
  text-align: left;
  ${mediaQueries.lg} {
    justify-content: center;
  }
  ${mediaQueries.md} {
    display: flex;
    flex-direction: column;
    background-color: ${color.white};
    border-radius: 1rem;
    width: 90vw;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  }
  ${mediaQueries.sm} {
    margin: 0;
    padding: 2rem;
  }
`;

const DedicatedSupportSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <SectionIcon small src={dedicatedSupportIcon} />
        </Col>
        <Col sm={24} md={12} lg={{ span: 10, offset: 4 }} xxl={10}>
          <S.CardHeading css={{ maxWidth: '30rem' }}>Dedicated Professional Support</S.CardHeading>
          <S.SectionDescription small>
            Match with a professional expert who will help you reach your goals and support you
            every step of the way.
          </S.SectionDescription>
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default DedicatedSupportSection;
