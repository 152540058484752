import React from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

import { mediaQueries, color } from '../variables';
import personalizedPlanIcon from '../../images/illu-personalized-plan.svg';
import * as S from '../shared/components/styles';
import { SectionIcon } from './styles';
import { ContentWrapper, ContentRow } from '../businesses/styles';

const SectionWrapper = styled(Row)`
  padding: 7rem 9rem 6rem 19rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 7rem 9rem 1rem 14rem;
  }
  ${mediaQueries.xxl} {
    padding: 5rem 9rem 6rem;
  }
  ${mediaQueries.xl} {
    padding: 5rem 5rem 1rem;
  }
  ${mediaQueries.lg} {
    padding: 5rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 5rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 1rem;
  }
`;

const PersonalizedPlanSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <S.CardHeading>Personalized Plan</S.CardHeading>
          <S.SectionDescription small>
            Take an assessment, measure your well-being, and learn which of the available tools are
            most helpful for you.
          </S.SectionDescription>
        </Col>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <SectionIcon src={personalizedPlanIcon} />
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default PersonalizedPlanSection;
