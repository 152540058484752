import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import WhatYouGetSection from '../components/employees/WhatYouGet';
import PersonalizedPlanSection from '../components/employees/PersonalizedPlanSection';
import DedicatedSupportSection from '../components/employees/DedicatedSupportSection';
import DigialCoursesSection from '../components/employees/DigitalCoursesSection';
import FooterWrapper from '../components/index/FooterWrapper';
import SuggestThisServiceSection from '../components/employees/SuggestThisService';

const ForEmployeesPage = () => (
  <Layout>
    <Navigation />
    <WhatYouGetSection />
    <PersonalizedPlanSection />
    <DedicatedSupportSection />
    <DigialCoursesSection />
    <FooterWrapper children={<SuggestThisServiceSection />} />
  </Layout>
);

export default ForEmployeesPage;
