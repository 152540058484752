import React from 'react';
import { Row, Col, Button } from 'antd';
import styled from '@emotion/styled';

import forEmployyesIcon from '../../images/illu-diving.svg';
import { mediaQueries, color } from '../variables';
import RequestTheseBenefitsFormModalWidget from '../shared/components/RequestTheseBenefitsFormModalWidget';
import * as S from '../shared/components/styles';

const SectionWrapper = styled(Row)`
  padding: 15rem 9rem 0;
  &:last-of-type {
    padding-bottom: 4rem;
  }
  ${mediaQueries.xl} {
    padding: 10rem 5rem 0;
  }
  ${mediaQueries.lg} {
    padding: 10rem 2rem 0;
  }
  ${mediaQueries.sm} {
    padding: 7rem 2rem 0;
  }
  background-color: ${color.white};
  background-size: cover;
`;

const ContentRow = styled(Row)`
  display: 'block';
  width: 100%;
  margin: 0 auto;
`;

const ContentColumn = styled(Col)`
  text-align: center;
`;

const SectionIcon = styled.img`
  display: flex;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  ${mediaQueries.xl} {
    height: 30rem;
  }
  ${mediaQueries.lg} {
    height: 24rem;
  }
  ${mediaQueries.sm} {
    height: 17rem;
    width: 20rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  && {
    width: 11rem;
    height: 3rem;
    margin-top: 2rem;
    margin-right: 1rem;
    border-radius: 2rem;
    color: white;
    background-color: ${color.royalBlue};
    font-size: 1rem;
    border-color: ${color.royalBlue};
    outline: none;
    box-shadow: 0 6px 12px 0 ${color.lavender};
    &:hover {
      background-color: ${color.royalBlueDark};
      color: white;
      border-color: ${color.royalBlueDark};
    }
    ${mediaQueries.sm} {
      width: 20rem;
      margin: 2rem auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

const WhatYouGetSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentColumn>
        <S.SectionWelcomeHeading>What you get through Modern Health</S.SectionWelcomeHeading>
        <S.SectionDescription semiTranspatentOpacity css={{ color: color.manatee }}>
          It’s time to take care of your mental well-being.
        </S.SectionDescription>
        <S.ButtonsWrapper>
          <a href="https://my.joinmodernhealth.com/login">
            <StyledButton>Log in</StyledButton>
          </a>
          <RequestTheseBenefitsFormModalWidget />
        </S.ButtonsWrapper>
      </ContentColumn>
    </ContentRow>
    <IconWrapper>
      <SectionIcon src={forEmployyesIcon} />
    </IconWrapper>
  </SectionWrapper>
);

export default WhatYouGetSection;
