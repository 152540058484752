import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { mediaQueries, color } from '../../variables';
import RequestTheseBenefitsFormModal from './RequestTheseBenefitsFormModal';
import { LabelButtonWrapper } from './styles';

const RequestBenefitsBtn = styled(Button)`
  && {
    padding: 0;
    width: 13.5rem;
    height: 3.1rem;
    margin-top: 0.75rem;
    margin-right: 0.5rem;
    border-radius: 2rem;
    color: ${color.echoBlue};
    background-color: white;
    font-size: 1rem;
    border: solid 2.5px ${color.linkWater};
    outline: none;
    &:hover {
      background-color: #ededf1;
      border-color: ${color.linkWater};
    }
    ${mediaQueries.sm} {
      width: 20rem;
      margin: 0 auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

class RequestTheseBenefitsFormModalWidget extends React.Component {
  state = {
    isRequestTheseBenefitsModalOpen: false,
  };

  toggleRequestTheseBenefitsModal = () =>
    this.setState(state => ({
      isRequestTheseBenefitsModalOpen: !state.isRequestTheseBenefitsModalOpen,
    }));

  render() {
    return (
      <>
        <RequestBenefitsBtn onClick={this.toggleRequestTheseBenefitsModal}>
          <LabelButtonWrapper>Request these benefits</LabelButtonWrapper>
        </RequestBenefitsBtn>
        <RequestTheseBenefitsFormModal
          isVisible={this.state.isRequestTheseBenefitsModalOpen}
          onCancel={this.toggleRequestTheseBenefitsModal}
        />
      </>
    );
  }
}

export default RequestTheseBenefitsFormModalWidget;
