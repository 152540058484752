import React from 'react';
import { Col } from 'antd';

import digitalCoursesIcon from '../../images/illu-meditation.svg';
import * as S from '../shared/components/styles';
import { SectionIcon } from './styles';
import { SectionWrapper, ContentRow, ContentWrapper } from '../businesses/styles';

const DigialCoursesSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <S.CardHeading>Digital Courses & Meditation Library</S.CardHeading>
          <S.SectionDescription>
            Get unlimited access to evidence-based programs and guided exercises that will help you
            combat burnout in as little as 5 minutes.
          </S.SectionDescription>
        </Col>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <SectionIcon large src={digitalCoursesIcon} />
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default DigialCoursesSection;
