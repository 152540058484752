import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import { mediaQueries } from '../variables';
import RequestTheseBenefitsFormModal from '../shared/components/RequestTheseBenefitsFormModal';
import { RequestDemoButton } from '../shared/components/styles';

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const SectionHeading = styled.h2`
  color: white;
  margin: '0 0 1rem';
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 1rem;
  ${mediaQueries.sm} {
    font-size: 28px;
  }
`;

const SectionWrapper = styled(Col)`
  padding: 11rem 1rem 3rem;
  width: 100%;
  background-color: transparent;
  background-size: cover;
  ${mediaQueries.sm} {
    padding: 2rem 1rem 0;
  }
`;

const HeaderWrapper = styled.div`
  display: block;
  justify-content: center;
`;

class SuggestThisServiceSection extends React.Component {
  state = {
    isRequestTheseBenefitsModalOpen: false,
  };

  toggleRequestTheseBenefitsModal = () =>
    this.setState(state => ({
      isRequestTheseBenefitsModalOpen: !state.isRequestTheseBenefitsModalOpen,
    }));

  render() {
    return (
      <>
        <SectionWrapper>
          <ContentRow>
            <HeaderWrapper>
              <SectionHeading>Ask your employer for these benefits</SectionHeading>
              <RequestDemoButton onClick={this.toggleRequestTheseBenefitsModal}>
                Request these benefits
              </RequestDemoButton>
            </HeaderWrapper>
          </ContentRow>
        </SectionWrapper>
        <RequestTheseBenefitsFormModal
          isVisible={this.state.isRequestTheseBenefitsModalOpen}
          onCancel={this.toggleRequestTheseBenefitsModal}
        />
      </>
    );
  }
}

export default SuggestThisServiceSection;
